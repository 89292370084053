import React from 'react'
import styled from 'styled-components'

const ExploreContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 80px);
  overflow: hidden;
`
export default function Explore() {
  return (
    <ExploreContainer>
      <iframe style={{width:'100%',height:'100%',overflow: 'hidden'}} src="https://explore.mswap.info/"></iframe>
    </ExploreContainer>
  )
}