// import MAT from './MAT.svg'
import BNB from './BNB.svg'
import TOX from './TOX.png'
import TOXA from './TOXA.png'
import TOXB from './TOXB.png'
import GLD from './GLD.png'
import weth from './weth.png'
import TOXTOKEN from './TOXTOKEN.png'
import X from './x.png'

const ImageToken: any = {
  BNB: BNB,
  WBNB: BNB,
  TOX:TOXTOKEN,
  TOXA,
  TOXB,
  GLD,
  USDT: TOX,
  USDC: TOXB,
  WETH: weth,
  X
}
export default ImageToken
