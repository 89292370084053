import { ChainId } from '@uniswap/sdk'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'

import styled from 'styled-components'

import Logo from '../../assets/images/LOGO.svg'
import Menu from '../../assets/images/menu.svg'
import LogoDark from '../../assets/images/LOGO_white.svg'
import IconLang from '../../assets/images/icon_lang.png'
import IconLangDark from '../../assets/images/icon_lang_white.png'
// import IconNet from '../../assets/images/icon_net.png'
// import IconNetDark from '../../assets/images/icon_net_white.png'
import IconNet from '../../assets/svg/icon_matchain.svg'
import IconNetDark from '../../assets/svg/icon_matchain.svg'
// import Wordmark from '../../assets/svg/wordmark.svg'
// import WordmarkDark from '../../assets/svg/wordmark_white.svg'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'

import { YellowCard } from '../Card'
// import Settings from '../Settings'
// import Menu from '../Menu'

import Row, { RowBetween } from '../Row' //Row,
import Web3Status from '../Web3Status'
import { useLanguageModalToggle, useMenuModelToggle, useNetModalToggle } from '../../state/application/hooks'
import LanguageModal from '../LanguageModal'
import NetModal from '../NetModal'
import { HeaderTabs } from '../NavigationTabs/HeaderIndex'
import MenuModel from '../MenuModel'
// import VersionSwitch from './VersionSwitch'

const HeaderFrame = styled.div`
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

// const HeaderElementWrap = styled.div`
//   display: flex;
//   align-items: center;

//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     margin-top: 0.5rem;
// `};
// `

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`
const HeaderTab = styled(Row)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`
// const TitleText = styled(Row)`
//   width: fit-content;
//   white-space: nowrap;
//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//     display: none;
//   `};
// `

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    img { 
      width: 6rem;
    }
  `};
`
const SmallIcon = styled.div`
  transition: transform 0.3s ease;
  margin-right: 12px;
  :hover {
    transform: rotate(-5deg);
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
    img { 
      width: 2rem;
    }
  `};
`

const NetIcon = styled.div<{ size?: number }>`
  transition: transform 0.3s ease;
  margin-right: 12px;
  :hover {
    transform: rotate(-5deg);
  }
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`
// ${({ theme }) => theme.mediaWidth.upToSmall`
// display: none;
//   img {
//     width: 2rem;
//   }
// `};

const MenuIcon = styled.div`
  transition: transform 0.3s ease;
  display: none;
  margin-right: 12px;
  :hover {
    transform: rotate(-5deg);
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    img {
      width: 2rem;
    }
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-end;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: null,
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.MATCH]: 'Matchain'
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const toggleLanguageModal = useLanguageModalToggle()
  const toggleNetModal = useNetModalToggle()
  const toggleMenuModal = useMenuModelToggle()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [isDark] = useDarkModeManager()

  return (
    <HeaderFrame>
      <RowBetween style={{ alignItems: 'center' }} padding="1rem 1rem 0 1rem">
        <Row>
          <HeaderElement>
            <Title href=".">
              <UniIcon>
                <img src={isDark ? LogoDark : Logo} alt="logo" />
              </UniIcon>
              {/* <TitleText>
              <img style={{ marginLeft: '4px', marginTop: '4px' }} src={isDark ? WordmarkDark : Wordmark} alt="logo" />
            </TitleText> */}
            </Title>
          </HeaderElement>
          <MenuIcon onClick={toggleMenuModal}>
            <img src={Menu} alt="logo" />
          </MenuIcon>
          <HeaderTab>
            <HeaderTabs />
          </HeaderTab>
        </Row>
        <HeaderControls>
          <HeaderElement>
            <TestnetWrapper>
              {!isMobile && chainId && NETWORK_LABELS[chainId] && <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>}
            </TestnetWrapper>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} BNB
                </BalanceText>
              ) : null}
              <SmallIcon onClick={toggleLanguageModal}>
                <img src={isDark ? IconLangDark : IconLang} alt="logo" />
              </SmallIcon>
              {/* {account && ( */}
                <NetIcon onClick={toggleNetModal}>
                  <img src={isDark ? IconNetDark : IconNet} alt="logo" />
                </NetIcon>
              {/* )} */}
              <LanguageModal />
              <MenuModel />
              <NetModal />
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          {/* <HeaderElementWrap>
            <VersionSwitch />
            <Settings />
            <Menu />
          </HeaderElementWrap> */}
        </HeaderControls>
      </RowBetween>
    </HeaderFrame>
  )
}
